import axios from 'axios'
import { API_ENDPOINT } from '../../../config'
import store from '../../../redux/stores/store'
import Validation from '../../../data/validation/validation'
import { StringValidationRule, NumberValidationRule, DateValidationRule } from '../../../data/validation/rules'

export const LoadAuthors = instance => {
    axios({
        method: 'get',
        url: API_ENDPOINT + "/api/publisher/authors",
        headers: {
            "Authorization": store.getState().auth.jwt
        }
    }).then(response => handleLoadAuthorsResponse(instance, response))
    .catch(error => alert(error))
}

const handleLoadAuthorsResponse = (instance, response) => {
    if (response.status !== 200) {
        return
    }
    switch (response.data.status) {
        case 200:
            instance.setState({
                ...instance.state,
                authors: response.data.data.authors
            })
            break
        default:
            alert(response.data.message)
    }
}

export const LoadJournals = instance => {
    instance.setState({
        ...instance.state,
        loadingJournals: true
    })

    let criteria = {}

    axios({
        method: 'post',
        url: API_ENDPOINT + "/api/journal/journals",
        headers: {
            "Authorization": store.getState().auth.authorization
        },
        data: {
            offset: 0,
            limit: 100,
            criteria: criteria
        }
    }).then(response => handleLoadJournalsResponse(instance, response))
    .catch(error => alert(error))
}

const handleLoadJournalsResponse = (instance, response) => {
    if (response.status !== 200) {
        return
    }
    switch (response.data.status) {
        case 200:
            instance.setState({
                ...instance.state,
                loadingJournals: false,
                journals: response.data.data.journals
            })
            break
        default:
            instance.setState({
                ...instance.state,
                loadingJournals: false,
                flag: {
                    type: "error",
                    text: response.data.message
                }
            })
    }
}

export const LoadArticleTypes = instance => {
    instance.setState({
        ...instance.state,
        loadingArticleTypes: true
    })

    let criteria = {}

    axios({
        method: 'post',
        url: API_ENDPOINT + "/api/article/article-types",
        headers: {
            "Authorization": store.getState().auth.authorization
        },
        data: {
            offset: 0,
            limit: 100,
            criteria: criteria
        }
    }).then(response => handleLoadArticleTypesResponse(instance, response))
    .catch(error => alert(error))
}

const handleLoadArticleTypesResponse = (instance, response) => {
    if (response.status !== 200) {
        return
    }
    switch (response.data.status) {
        case 200:
            instance.setState({
                ...instance.state,
                loadingArticleTypes: false,
                articleTypes: response.data.data.articleTypes
            })
            break
        default:
            instance.setState({
                ...instance.state,
                loadingArticleTypes: false,
                flag: {
                    type: "error",
                    text: response.data.message
                }
            })
    }
}

export const IsValid = instance => {
    let validation = new Validation()
    let errors = instance.state.errors
    validation.addValidationRule(StringValidationRule, instance.state.journalId, (error) => errors.journalId = error, { min: { value: 20, error: "Too short" }, max: { value: 45, error: "Too long" } })
    validation.addValidationRule(StringValidationRule, instance.state.articleTypeId, (error) => errors.articleTypeId = error, { min: { value: 20, error: "Too short" }, max: { value: 45, error: "Too long" } })
    validation.addValidationRule(StringValidationRule, instance.state.title, (error) => errors.title = error, { min: { value: 2, error: "Too short" }, max: { value: 650, error: "Too long" } })
    validation.addValidationRule(StringValidationRule, instance.state.keywords, (error) => errors.keywords = error, { min: { value: 0, error: "Too short" }, max: { value: 100, error: "Too long" } })
    validation.addValidationRule(StringValidationRule, instance.state.description, (error) => errors.description = error, { min: { value: 0, error: "Too short" }, max: { value: 300, error: "Too long" } })
    validation.addValidationRule(NumberValidationRule, instance.state.pageStart, (error) => errors.pageStart = error, { min: { value: 1, error: "Too low" }, max: { value: 50000, error: "Too high" } })
    validation.addValidationRule(NumberValidationRule, instance.state.pageEnd, (error) => errors.pageEnd = error, { min: { value: 1, error: "Too low" }, max: { value: 50000, error: "Too high" } })
    validation.addValidationRule(DateValidationRule, instance.state.publicationDate, (error) => errors.publicationDate = error, { allowNull: false})
    validation.addValidationRule(StringValidationRule, instance.state.doi, (error) => errors.doi = error, { min: { value: 0, error: "Too short" }, max: { value: 200, error: "Too long" } })
    validation.addValidationRule(StringValidationRule, instance.state.abstract, (error) => errors.abstract = error, { min: { value: 5, error: "Too short" }, max: { value: 9000000, error: "Too long" } })
    let validate = validation.validate()
    let stateUpdate = {
        errors: errors,
        flag: {
            type: validate ? "" : "error",
            text: validate ? "" : "Validation Failure"
        }
    }
    instance.setState({
        ...instance.state,
        ...stateUpdate
    })
    return validate
}