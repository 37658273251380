import axios from 'axios'
import { API_ENDPOINT } from '../../../config'
import store from '../../../redux/stores/store'

const getDefaultState = () => {
    return {
        loading: false,
        articles: [],
        filter: "",
        selectedIndex: -1,
        currentPage: 1,
        pageSize: 20,
        total: 0
    }
}

const loadArticles = instance => {
    instance.setState({ ...instance.state, loading: true })

    let data = {
        offset: (instance.state.currentPage - 1) * instance.state.pageSize,
        limit: instance.state.pageSize,
        criteria: {}
    }

    let url = API_ENDPOINT + "/api/articles"
    
    if (instance.state.filter.length > 0) {
        data.title = instance.state.filter
        url += "/title"
    }

    axios({
        method: 'post',
        url: url,
        headers : {
            "Authorization": store.getState().auth.authorization
        },
        data: data
    }).then(response => handleLoadArticlesResponse(instance, response))
    .catch(error => alert(error))
}

const handleLoadArticlesResponse = (instance, response) => {
    if (response.status === 200 && response.data.status === 200) {
        instance.setState({
            ...instance.state,
            loading: false,
            articles: response.data.data.articles
        })
    }
    else if (response.data.status === 403) {
        instance.props.history.push('/auth/login')
    }
}

const ArticleWorker = instance => {
    return {
        loadArticles: () => loadArticles(instance),
        getDefaultState: () => getDefaultState(instance)
    }
}

export default ArticleWorker