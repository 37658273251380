import axios from 'axios'
import { API_ENDPOINT } from '../../../../config'
import store from '../../../../redux/stores/store'
import {LoadAuthors, LoadArticleTypes, LoadJournals, IsValid} from '../article.worker'
import { ScrollIntoViewById } from '../../../../utils/UIHelper'

const getDefaultState = () => {
    return {
        creating: false,
        loadingJournals: false,
        loadingTypes: false,
        title: "",
        doi: "",
        journalId: "",
        articleTypeId: "",
        keywords: "",
        description: "",
        abstract: "",
        publicationDate: "",
        pageStart: "",
        pageEnd: "",
        errors: {},
        journals: [],
        articleTypes: [],
        flag: {
            type: "",
            text: ""
        }
    }
}

const createArticle = (instance) => {
    instance.setState({
        ...instance.state,
        creating: true
    })
    axios({
        method: 'post',
        url: API_ENDPOINT + "/api/article/create",
        headers: {
            "Authorization": store.getState().auth.authorization
        },
        data: {
            title: instance.state.title,
            doi: instance.state.doi,
            pageStart: instance.state.pageStart,
            pageEnd: instance.state.pageEnd,
            journalId: instance.state.journalId,
            articleTypeId: instance.state.articleTypeId,
            keywords: instance.state.keywords,
            description: instance.state.description,
            publicationDate: instance.state.publicationDate,
            abstract: instance.state.abstract
        }
    }).then(response => handleCreateArticleResponse(instance, response))
    .catch(error => {
        instance.setState({
            ...instance.state,
            creating: false
        })
        alert(error)
    })
}

const handleCreateArticleResponse = (instance, response) => {
    if(response.status !== 200){
        return
    }
    ScrollIntoViewById("createArticle")
    switch (response.data.status) {
        case 200:
            instance.setState({
                ...getDefaultState(),
                flag: {
                    type: "success",
                    text: "Article Created"
                }
            })
            break
        default:
            instance.setState({
                ...instance.state,
                creating: false,
                flag: {
                    type: "error",
                text: response.data.message
                }
            })
    }
}

const CreateArticleWorker = instance => {
    return {
        getDefaultState: getDefaultState,
        isValid: () => IsValid(instance),
        loadJournals: () => LoadJournals(instance),
        loadArticleTypes: () => LoadArticleTypes(instance),
        loadAuthors: () => LoadAuthors(instance),
        createArticle: () => createArticle(instance)
    }
}

export default CreateArticleWorker